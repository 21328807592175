import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const VectorBackground = () => (
  <React.Fragment>
    <svg
      style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 90 }}
      width="3163"
      height="190"
      viewBox="0 0 3163 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19_257)">
        <path
          d="M1446.68 133.466L1553.96 193.864C1593.6 216.178 1642.88 211.795 1677.96 182.837L1698.21 166.119C1731.81 138.373 1778.63 133.085 1817.58 152.635L1920.68 204.385C1953.67 220.941 1992.75 219.853 2024.76 201.49L2116.99 148.581C2147.75 130.936 2185.13 129.201 2217.39 143.922L2354.73 206.588C2388.03 221.784 2426.71 219.418 2457.91 200.276L2537.36 151.535C2568.96 132.148 2608.2 129.984 2641.75 145.779L2761.41 202.13C2798.89 219.778 2843.1 214.875 2875.8 189.446L2902.24 168.883C2939.3 140.064 2990.77 138.6 3029.4 165.266C3080.79 200.731 3151.48 185.153 3183.19 131.371L3293.18 -55.1217C3336.42 -128.45 3283.56 -221 3198.42 -221L15.0685 -221C-32.1895 -221 -74.1666 -190.815 -89.2095 -146.015L-143.143 14.6055C-157.032 55.9688 -145.16 101.631 -112.884 130.992L-64.356 175.134C-28.0615 208.149 25.71 213.135 67.4563 187.357L117.307 156.575C153.083 134.483 198.323 134.719 233.867 157.182L299.42 198.61C331.236 218.717 371.123 221.162 405.156 205.091L524.789 148.599C555.756 133.976 591.767 134.61 622.2 150.313L725.022 203.37C758.272 220.527 797.963 219.606 830.382 200.926L914.415 152.504C948.769 132.709 991.118 132.933 1025.26 153.092L1103.7 199.407C1136.82 218.957 1177.73 219.798 1211.62 201.623L1340.73 132.382C1373.9 114.588 1413.87 114.998 1446.68 133.466Z"
          fill="#282828"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_19_257"
          x="-175.869"
          y="-239"
          width="3495.46"
          height="475.514"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-8" dy="1" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19_257"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19_257"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </React.Fragment>
);

const WHITESPACE = styled.div`
  padding-top: 128px;
`;

const Root = styled.div`
  padding: 32px 64px;
  display: flex;
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  z-index: 100;
`;

const Nav = styled.nav`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  column-gap: 64px;
`;

const NavItem = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  padding: 16px;
  &:hover {
    cursor: pointer;
    background-color: #171717;
  }
  z-index: 100;
`;

const navItems = [
  {
    text: "PROJECTS",
    link: "/projects",
  },
  {
    text: "CONTACT",
    link: "/contact",
  },
];

export default () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Root>
        <NavItem onClick={() => navigate("/")}>BERGET INDUSTRIES</NavItem>
        <Nav>
          {navItems.map(({ text, link }) => (
            <NavItem onClick={() => navigate(link)}>{text}</NavItem>
          ))}
        </Nav>
      </Root>
      <WHITESPACE />
    </React.Fragment>
  );
};
