import React, { createRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import anime from "animejs";

import { default as TitleDefault } from "../components/Title";

const AnimatedRoot = styled.span`
  display: flex;
  position: relative;
`;

const AnimatedChild = styled.span`
  position: absolute;
  top: 0;
  left: 0;
`;
const AnimatedHidden = styled.span`
  opacity: 0;
`;

const Animated = ({ config, children }) => {
  const [complete, setComplete] = useState(false);
  const ref = createRef();

  const animate = (c) => {
    anime({
      duration: 800,
      easing: "easeOutQuint",
      targets: ref.current,
      complete: () => {
        console.log("ANIMATION COMPLETE");
      },
      ...config,
    });
  };

  useEffect(() => {
    if (Boolean(config) && Boolean(children) && !complete) {
      setComplete(true);
      animate();
      console.log("animate");
    }
  }, [config, children]);

  return (
    <AnimatedRoot ref={ref}>
      <AnimatedChild>{children}</AnimatedChild>
      <AnimatedHidden>{children}</AnimatedHidden>
    </AnimatedRoot>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 256px);
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

const Title = styled(TitleDefault)`
  font-size: 128px;
`;

const OutlinedText = styled(Title)`
  color: #171717 !important;
  font-size: 128px;
  text-shadow: 1px 0px 0px rgba(18, 78, 235, 1),
    -1px 0px 0px rgba(18, 78, 235, 1), 0px 1px 0px rgba(18, 78, 235, 1),
    0px -1px 0px rgba(18, 78, 235, 1); !important
`;

export default function LandingPage() {
  return (
    <Root>
      <Row>
        <Animated config={{ bottom: [1000, 0] }}>
          <OutlinedText>Our</OutlinedText>
        </Animated>
        <Animated config={{ bottom: [1000, 0] }}>
          <Title> software</Title>
        </Animated>
      </Row>

      <Row>
        <Animated config={{ right: [500, 0], opacity: [0, 1] }}>
          <OutlinedText> isn't just</OutlinedText>
        </Animated>
      </Row>
      <Row>
        <Animated config={{ right: [500, 0], opacity: [0, 1] }}>
          <Title>functional,</Title>
        </Animated>
      </Row>

      <Row>
        <Animated config={{ left: [500, 0] }}>
          <OutlinedText>it's a</OutlinedText>
        </Animated>
      </Row>
      <Row>
        <Animated config={{ top: [500, 0], opacity: [0, 1] }}>
          <Title>work</Title>
        </Animated>
        <Animated config={{ top: [500, 0], opacity: [0, 1] }}>
          <OutlinedText>of</OutlinedText>
        </Animated>
        <Animated config={{ top: [500, 0], opacity: [0, 1] }}>
          <Title>art.</Title>
        </Animated>
      </Row>
    </Root>
  );
}
