import Router from "./routes";
import Header from "./components/Header";
import WavesBackground from "./components/WavesBackground";

export default function App() {
  return (
    <div>
      <Header />
      <Router />
    </div>
  );
}
