import React from "react";
import styled from "styled-components";

const base = `
  background-color: #292929;
  padding: 16px 24px;
  color: #fff;
  font-size: 24px;
  border: none;
  border-radius 16px;
  placeholder-color: rgba(255,255,255,.5);
  box-sizing: border-box;
  max-width: 660px;
`;

const BaseInput = styled.input`
  ${base}
`;

const BaseMultiLine = styled.textarea`
  ${base}
  resize: none;
`;

export default function TextInput({ multiline, ...props }) {
  return multiline ? (
    <BaseMultiLine rows={10} {...props} />
  ) : (
    <BaseInput {...props} />
  );
}
