import React from "react";
import styled from "styled-components";

const ButtonBase = styled.button`
  font-size: 24px;
  color: #fff;
  padding: 16px;
  background-color: #124eeb;
  text-transform: uppercase;
  border: none;
  border-radius: 16px;
  &:hover {
    cursor: pointer;
    background-color: rgba(18, 78, 235, 0.5);
  }
`;

export default function Button(props) {
  return <ButtonBase {...props} />;
}
