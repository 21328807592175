import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 64px;
  font-weight: 700;
  color: #${(props) => (props.highlight ? "124EEB" : "fff")};
  text-transform: uppercase;
`;

export default Title;
