import React, { createRef, useEffect, useState, useRef } from "react";
import { Navigate, useRoutes, Outlet, useLocation } from "react-router-dom";
import anime from "animejs";

import ProjectsPage from "./pages/ProjectsPage";
import ContactPage from "./pages/ContactPage";
import LandingPage from "./pages/LandingPage";

const allRoutes = [
  {
    index: true,
    element: <LandingPage />,
  },
  {
    path: "projects",
    children: [
      {
        index: true,
        element: <ProjectsPage />,
      },
    ],
  },
  {
    path: "contact",
    element: <ContactPage />,
  },
  {
    path: "*",
    element: <h1>404 - Not Found</h1>,
  },
];

export default function AppRoutes() {
  const animationElemet = createRef();
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);

  const animate = (config) => {
    anime({
      duration: 300,
      easing: "easeInOutCirc",
      targets: animationElemet.current,
      complete: () => {
        console.log("ANIMATION COMPLETE");
        setDisplayLocation(location);
      },
      ...config,
    });
  };

  useEffect(() => {
    if (location === displayLocation) {
      animate({ opacity: 1 }); // Animate Open
    }
    if (location !== displayLocation) {
      animate({ opacity: 0 }); // Animate Close
    }
  }, [location, displayLocation]);

  const routes = useRoutes(allRoutes, displayLocation);

  return (
    <div style={{ position: "absolute", width: "100%" }} ref={animationElemet}>
      {routes}
    </div>
  );
}
