import React from "react";
import styled from "styled-components";

import AnimateChildren from "../utils/AnimateChildren";

import Title from "../components/Title";
import TextInput from "../components/TextInput";
import Button from "../components/Button";

const Root = styled.div`
  margin: auto;
  width: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
`;

export default function ContactPage() {
  return (
    <Root>
      <Title>Contact</Title>
      <TextInput placeholder="Subject" width="660px" />
      <TextInput placeholder="Subject" multiline={true} />
      <Button>Send</Button>
    </Root>
  );
}
