import React, { useEffect, createRef, useState, useRef } from "react";
import styled from "styled-components";
import Divider from "../components/Divider";
import anime from "animejs";

const projectList = [
  {
    title: "CHICAGO\nCOUNTER",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam felis elit, fringilla ut erat vitae, malesuada porta sem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    link: "/projects/chicago-counter",
  },
  {
    title: "PROJECT\n47_50",
    text: "Proin scelerisque sodales urna non feugiat. Nullam non nisi pellentesque leo feugiat efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris ex velit, ultrices nec dolor sit amet, efficitur fringilla velit. Sed mattis iaculis pretium. Praesent fringilla consectetur congue. Duis accumsan vehicula elit.",
    link: "/projects/chicago-counter",
  },
  {
    title: "RANDOM\nDICE.JS",
    text: "Cras a metus id ante rutrum efficitur vitae non turpis. Phasellus eros elit, lacinia et blandit vel, interdum et odio. Fusce malesuada suscipit dolor sit amet malesuada. Phasellus commodo risus ut est pretium mollis. Quisque nec libero ultricies, molestie nibh varius, mattis nulla.",
    link: "/projects/chicago-counter",
  },
];

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ListItemRoot = styled.div`
  color: #fff;
  &:hover {
    cursor: pointer;
    color: rgba(18, 78, 235, 0.5);
  }
  width: 600px;
`;

const ListItemTitle = styled.h1`
  font-size: 128px;
  font-weight: 800;
`;

const ListItemText = styled.p`
  font-size: 18px;
  font-weight: 400;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  visibility: hidden;
  position: absolute;
  padding: 0 32px;
`;

const ListItem = ({ title, text, link }) => {
  const ref = useRef();
  const [refDefaultHeight, setRefDefaultHeight] = useState();
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    if (!refDefaultHeight) {
      setRefDefaultHeight(ref.current.offsetHeight);
    }
  }, [ref.current]);

  useEffect(() => {
    if (refDefaultHeight) {
      ref.current.style.height = 0;
      ref.current.style.position = "static";
      ref.current.style.visibility = "visible";
    }
  }, [refDefaultHeight]);

  useEffect(() => {
    if (!ref.current) return;
    if (!refDefaultHeight) return;

    const animate = (config) =>
      anime({
        targets: ref.current,
        duration: 400,
        easing: "easeInOutCirc",
        complete: () => {
          console.log("ANIMATION COMPLETE");
        },
        ...config,
      });

    if (isHovering) {
      animate({
        height: refDefaultHeight + 64,
        paddingTop: 32,
        paddingBottom: 32,
      });
    } else {
      animate({ height: 0, paddingTop: 0, paddingBottom: 0 });
    }
  }, [isHovering]);

  const handleClick = () => {
    alert(link);
  };

  return (
    <ListItemRoot
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleClick}
    >
      {title.split("\n").map((x, i) => (
        <React.Fragment>
          <ListItemTitle>{x}</ListItemTitle>
          {i === 0 && <ListItemText ref={ref}>{text}</ListItemText>}
        </React.Fragment>
      ))}
    </ListItemRoot>
  );
};

export default () => {
  useEffect(() => {});

  return (
    <Root>
      {projectList.map((props, i) => (
        <React.Fragment>
          {i > 0 && <Divider />}
          <ListItem key={i} {...props} />
        </React.Fragment>
      ))}
    </Root>
  );
};
