import React from "react";

const Divider = () => (
  <svg
    style={{ margin: 64 }}
    width="582"
    height="16"
    viewBox="0 0 582 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="8"
      y1="8.00006"
      x2="574"
      y2="8.00001"
      stroke="#124EEB"
      strokeOpacity="0.28"
      strokeWidth="16"
      strokeLinecap="round"
    />
  </svg>
);

export default Divider;
